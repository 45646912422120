import React, { ReactElement, useContext } from 'react';
import Image from '@/components/base/image';
import Icon from '@/components/base/icon';
import { IPointMarkerPopup } from '@/components/base/google-map/point-marker/popup/types';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import { isEmpty } from 'lodash';
import { clsx } from '@/utils';
import Anchor from '@/components/base/anchor/Anchor';

const PointMarkerPopup: React.FC<IPointMarkerPopup> = ({
  data: point,
  onToggleAddRoute,
  onClose: onCloseBase,
}) => {
  const { isPointSelected } = useContext(PlanYourTripsContext);

  // data
  const isSelected = isPointSelected(point);

  // actions
  const onClickToggleAddRoute = (e: React.MouseEvent) => {
    e.preventDefault();
    onToggleAddRoute && onToggleAddRoute(point);
  };

  const onClose = (e: React.MouseEvent) => {
    e.preventDefault();
    onCloseBase && onCloseBase();
  };

  // renders
  const renderWithAnchor = (children: ReactElement) => {
    if (point.anchor) {
      if ('path' in point.anchor) {
        return <Anchor href={point.anchor.path}>{children}</Anchor>;
      }

      return <Anchor {...point.anchor}>{children}</Anchor>;
    }

    return children;
  };

  return renderWithAnchor(
    <div className="google-map-popup">
      {/*begin::Close Button*/}
      <button className="google-map-popup-close-btn" onClick={onClose}>
        <Icon name="icon-close-2" size="medium" />
      </button>
      {/*end::Close Button*/}
      <div className="google-map-popup-content">
        {!isEmpty(point.thumbnail) && (
          <div className="google-map-popup-content-image">
            <Image source={point.thumbnail} alt="point thumbnail" />
          </div>
        )}
        <div className="google-map-popup-content-body">
          {point.subtitle && (
            <div className="google-map-popup-content-body-subtitle">
              {point.subtitle}
            </div>
          )}
          {point.title && (
            <div className="google-map-popup-content-body-title">
              {point.title}
            </div>
          )}
        </div>
        <button
          className={clsx('google-map-popup-content-button', {
            'google-map-popup-content-button--added': isSelected,
          })}
          onClick={onClickToggleAddRoute}
        >
          <Icon name={isSelected ? 'icon-close-2' : 'icon-add-2'} />
          {isSelected ? <span>Remove</span> : <span>Add Your Route</span>}
        </button>
      </div>
    </div>
  );
};

export default PointMarkerPopup;
