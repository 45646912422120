import React, { useCallback, useContext, useMemo } from 'react';
import { IPlanYourTripsMapProps } from '@/components/widgets/plan-your-trip/components/layout/map/types';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import PlanYourTripPointList from '@/components/widgets/plan-your-trip/components/layout/point-list/PointList';
import { motion } from 'framer-motion';
import GoogleMap from '@/components/base/google-map/GoogleMap';
import { isEmpty, isNil } from 'lodash';
import { GoogleMapContext } from '@/components/base/google-map/Context';
import { toCoordinatesNumber } from '@/utils/base/map';
import { OptionType } from '@/utils/types';
import { PolylineF } from '@react-google-maps/api';
import PlanYourTripRouteSummaryCard from '@/components/widgets/plan-your-trip/components/layout/route-summary-card';
import PlanYourTripAdvancedMarkerClusterer from '@/components/widgets/plan-your-trip/components/base/advanced-clusterer';
import PlanYourTripMyRoutes from '@/components/widgets/plan-your-trip/components/layout/my-routes';
import PlanYourTripMapLoader from '@/components/widgets/plan-your-trip/components/layout/map/Loader/Loader';
import PlanYourTripTopbar from '@/components/widgets/plan-your-trip/components/layout/map/Topbar/Topbar';
import PlanYourTripBottombar from '@/components/widgets/plan-your-trip/components/layout/map/Bottombar/Bottombar';

// const GoogleMapTopbar = dynamic(
//   () => import('@/components/base/google-map/topbar/Topbar'),
//   { ssr: false }
// );

const animations = {
  map: {
    hidden: {
      opacity: 0,
      x: -100,
    },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.3,
        delay: 0.4,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const PlanYourTripMap: React.FC<IPlanYourTripsMapProps> = () => {
  const { travelMode } = useContext(GoogleMapContext);
  const {
    nearyRoute,
    setNearyRoute,
    selectedOriginOption,
    selectedDestinationOption,
    selectedRoutePoints,
  } = useContext(PlanYourTripsContext);

  // actions
  const getOptionCoordinates = useCallback(
    (option: OptionType | undefined | null) => {
      if (isNil(option)) {
        return null;
      }

      return toCoordinatesNumber({
        lat: option.rawData.lat,
        lng: option.rawData.lng,
      });
    },
    []
  );

  const onDirectionsCallback = (routeLeg: google.maps.DirectionsLeg | null) => {
    const details: any = {
      distance: null,
      duration: null,
    };

    if (isNil(routeLeg)) {
      setNearyRoute((prevRoute) => ({
        ...prevRoute,
        details,
      }));
      return;
    }

    if (routeLeg.distance) {
      details.distance = routeLeg.distance.text;
    }

    if (routeLeg.duration) {
      details.duration = routeLeg.duration.text;
    }

    setNearyRoute((prevRoute) => ({
      ...prevRoute,
      details,
    }));
  };

  // data
  const directionsOptions = useMemo(() => {
    return {
      origin: getOptionCoordinates(selectedOriginOption),
      destination: getOptionCoordinates(selectedDestinationOption),
      travelMode,
      waypoints: selectedRoutePoints.map((point) => ({
        lat: point.lat,
        lng: point.lng,
      })),
    };
  }, [
    selectedOriginOption,
    selectedDestinationOption,
    selectedRoutePoints,
    travelMode,
  ]);

  return (
    <motion.div className="google-map" variants={animations.map}>
      <div className="google-map-wrapper">
        <PlanYourTripMapLoader />
        <PlanYourTripPointList points={nearyRoute.points} />
        <PlanYourTripRouteSummaryCard />
        <PlanYourTripMyRoutes />
        <PlanYourTripTopbar />
        <PlanYourTripBottombar />
        <GoogleMap
          options={{
            zoom: 6,
          }}
          directions={directionsOptions}
          onDirectionsCallback={onDirectionsCallback}
        >
          {/* @NOTE: this is for only shows backend neary route steps for checking */}
          {process.env.NODE_ENV === 'development' &&
            !isEmpty(nearyRoute.steps) && (
              <PolylineF
                path={nearyRoute.steps}
                options={{
                  strokeColor: '#fc8600',
                  strokeOpacity: 0.25,
                  strokeWeight: 5,
                  clickable: false,
                  draggable: false,
                  editable: false,
                  visible: true,
                }}
              />
            )}
          {!isEmpty(nearyRoute.points) && (
            <PlanYourTripAdvancedMarkerClusterer
              points={nearyRoute.points}
              variant="pin"
            />
          )}
        </GoogleMap>
      </div>
    </motion.div>
  );
};

export default PlanYourTripMap;
