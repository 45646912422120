'use client';
import React, { useContext } from 'react';
import useDeviceType from '@/hooks/useDeviceType';
import Icon from '@/components/base/icon';
import { GoogleMapContext } from '@/components/base/google-map/Context';
import { clsx, setStorage } from '@/utils';
import { LOCAL_STORAGE_KEYS_GMAP } from '@/components/base/google-map/constants';
import { IPlanYourTripTopbarProps } from '@/components/widgets/plan-your-trip/components/layout/map/Topbar/types';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import { ITravelMode } from '@/components/base/google-map/types';
import { isNil } from 'lodash';
import {
  getShortDistance,
  getShortDuration,
} from '@/components/base/google-map/utils';

const PlanYourTripTopbar: React.FC<IPlanYourTripTopbarProps> = () => {
  const deviceType = useDeviceType();
  const { isDarkMap, setDarkMap, directions, travelMode, setTravelMode } =
    useContext(GoogleMapContext);
  const { nearyRoute, selectedRoutePoints } = useContext(PlanYourTripsContext);

  // actions
  const handleClickTravelModeBind = (mode: ITravelMode) => () => {
    setTravelMode(mode);
  };

  // actions
  const onToggleMapDarkMode = () => {
    setDarkMap((prevStatus) => {
      const newStatus = !prevStatus;
      setStorage(LOCAL_STORAGE_KEYS_GMAP.darkMode, newStatus ? '1' : '0');
      return newStatus;
    });
  };

  return (
    <div className="plan-your-trip-topbar">
      {/*begin::Route Info*/}
      {deviceType === 'mobile' && !isNil(directions) && (
        <div className="plan-your-trip-topbar-route-info">
          <div className="plan-your-trip-topbar-route-info-travel-modes">
            <button
              className={clsx(
                'plan-your-trip-topbar-route-info-travel-modes-item',
                {
                  active: travelMode === 'DRIVING',
                }
              )}
              title="By Car"
              onClick={handleClickTravelModeBind('DRIVING')}
            >
              <Icon name="icon-car" />
            </button>
            <button
              className={clsx(
                'plan-your-trip-topbar-route-info-travel-modes-item',
                {
                  active: travelMode === 'TRANSIT',
                }
              )}
              title="Public Transport"
              onClick={handleClickTravelModeBind('TRANSIT')}
            >
              <Icon name="icon-bus" />
            </button>
            <button
              className={clsx(
                'plan-your-trip-topbar-route-info-travel-modes-item',
                {
                  active: travelMode === 'WALKING',
                }
              )}
              title="On Foot"
              onClick={handleClickTravelModeBind('WALKING')}
            >
              <Icon name="icon-walk" />
            </button>
          </div>
          {!isNil(nearyRoute.details?.distance) &&
            !isNil(nearyRoute.details.duration) && (
              <>
                <div className="plan-your-trip-topbar-route-info-bracket"></div>
                <div
                  className={clsx('plan-your-trip-topbar-route-info-details', {
                    active: selectedRoutePoints.length >= 2,
                  })}
                >
                  <div
                    className="plan-your-trip-topbar-route-info-details-item"
                    title={nearyRoute.details?.distance}
                  >
                    <Icon name="icon-distance" />
                    <span>
                      {getShortDistance(nearyRoute.details?.distance)}
                    </span>
                  </div>
                  <div className="plan-your-trip-topbar-bracket" />
                  <div
                    className="plan-your-trip-topbar-route-info-details-item"
                    title={nearyRoute.details?.duration}
                  >
                    <Icon name="icon-clock-2" />
                    <span>
                      {getShortDuration(nearyRoute.details?.duration)}
                    </span>
                  </div>
                </div>
              </>
            )}
        </div>
      )}
      {/*end::Route Info*/}
      {deviceType !== 'mobile' && (
        <div className="plan-your-trip-topbar-item">
          <button
            className={clsx(
              'plan-your-trip-topbar-button plan-your-trip-topbar-button--dark-theme',
              {
                'plan-your-trip-topbar-button--active': isDarkMap,
              }
            )}
            title={!isDarkMap ? 'Dark' : 'Light'}
            onClick={onToggleMapDarkMode}
          >
            <span>
              {isDarkMap ? <Icon name="icon-sun" /> : <Icon name="icon-moon" />}
            </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default PlanYourTripTopbar;
