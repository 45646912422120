'use client';
import React, { useContext } from 'react';
import { IPlanYourTripBottombarProps } from '@/components/widgets/plan-your-trip/components/layout/map/Bottombar/types';
import useDeviceType from '@/hooks/useDeviceType';
import { clsx } from '@/utils';
import Icon from '@/components/base/icon';
import { GoogleMapContext } from '@/components/base/google-map/Context';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';

const PlanYourTripBottombar: React.FC<IPlanYourTripBottombarProps> = () => {
  const deviceType = useDeviceType();
  const { showPointList, setShowPointList } = useContext(GoogleMapContext);
  const {
    showMyRoutes,
    setShowMyRoutes,
    selectedRoutePoints,
    resetSelectedPoints,
    setShowCurrentRouteSummaryCard,
  } = useContext(PlanYourTripsContext);

  // actions
  const onToggleShowMyRoutes = () => {
    setShowPointList(false);
    setShowCurrentRouteSummaryCard(false);
    setShowMyRoutes((prevStatus) => !prevStatus);
  };

  const onToggleShowList = () => {
    setShowMyRoutes(false);
    setShowPointList((prevStatus) => !prevStatus);
  };

  const onClickClear = () => {
    resetSelectedPoints();
  };

  // renders
  if (deviceType !== 'mobile') {
    return null;
  }

  return (
    <div className="plan-your-trip-bottombar">
      {/*begin::Button*/}
      {selectedRoutePoints.length > 0 && (
        <button
          className="plan-your-trip-bottombar-button plan-your-trip-bottombar-button--text plan-your-trip-bottombar-button--danger"
          onClick={onClickClear}
        >
          Clear
        </button>
      )}
      {/*end::Button*/}
      {/*begin::Button*/}
      <button
        className={clsx('plan-your-trip-bottombar-my-routes-button', {
          active: showMyRoutes,
        })}
        onClick={onToggleShowMyRoutes}
      >
        <div className="plan-your-trip-bottombar-my-routes-button-shape">
          <img src="/images/map/my-routes-shape.svg" />
        </div>
      </button>
      {/*end::Button*/}
      {/*begin::Button*/}
      <button
        className={clsx(
          'plan-your-trip-bottombar-button plan-your-trip-bottombar-button--icon',
          {
            'plan-your-trip-bottombar-button--active': showPointList,
          }
        )}
        title={!showPointList ? 'Show Point List' : 'Hide Point List'}
        onClick={onToggleShowList}
      >
        <Icon name="icon-see-list" />
      </button>
      {/*end::Button*/}
    </div>
  );
};

export default PlanYourTripBottombar;
