import React, { useContext } from 'react';
import PlanYourTripMyRoutesStickyButton from '@/components/widgets/plan-your-trip/components/layout/my-routes/sticky-button';
import PlanYourTripMyRoutesCard from '@/components/widgets/plan-your-trip/components/layout/my-routes/card/Card';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import { isEmpty } from 'lodash';
import { clsx } from '@/utils';

const PlanYourTripMyRoutes: React.FC = () => {
  const { nearyRoute, showMyRoutes } = useContext(PlanYourTripsContext);
  // const { authData } = useAuthStore();

  return (
    <div
      className={clsx('plan-your-trip-my-routes', {
        'has-point-list': !isEmpty(nearyRoute.points),
      })}
    >
      {/* {authData?.isAuth && <PlanYourTripMyRoutesStickyButton />} */}
      <PlanYourTripMyRoutesStickyButton />
      {showMyRoutes && <PlanYourTripMyRoutesCard />}
    </div>
  );
};

export default PlanYourTripMyRoutes;
