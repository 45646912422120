import Icon from '@/components/base/icon';
import React, { useContext, useState } from 'react';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import NoRecords from '@/components/base/no-records';
import { isEmpty } from 'lodash';
import {
  getShortDistance,
  getShortDuration,
} from '@/components/base/google-map/utils';
import { RouteProps } from '@/components/widgets/plan-your-trip/types';
import PYTRouteDeleteModal from '@/components/widgets/plan-your-trip/components/modals/route-delete/RouteDeleteModal';

const PlanYourTripMyRoutesCard: React.FC = () => {
  const {
    routes,
    setShowMyRoutes,
    changeCurrentRoute,
    setEditing,
    deleteRouteById,
  } = useContext(PlanYourTripsContext);

  // states
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [processingRoute, setProcessingRoute] = useState<RouteProps | null>(
    null
  );

  // actions
  const onClose = () => {
    setShowMyRoutes(false);
  };

  const handleClickViewBind = (route: RouteProps) => () => {
    setEditing(false);
    changeCurrentRoute(route.id);
    onClose();
  };

  const handleClickEditBind = (route: RouteProps) => () => {
    setEditing(true);
    changeCurrentRoute(route.id);
    onClose();
  };

  const handleClickDeleteBind = (route: RouteProps) => () => {
    setDeleting(true);
    setProcessingRoute(route);
  };

  const handleDeleteModalClose = () => {
    setDeleting(false);
    setProcessingRoute(null);
  };

  const handleDeleteModalCallback = () => {
    if (processingRoute) {
      deleteRouteById(processingRoute.id);
    }

    handleDeleteModalClose();
  };

  return (
    <div className="plan-your-trip-my-routes-card">
      {/*begin::Close Btn*/}
      <div
        className="plan-your-trip-my-routes-card-close-btn"
        onClick={onClose}
      >
        <Icon name="icon-close" />
      </div>
      {/*end::Close Btn*/}

      {isEmpty(routes) ? (
        <NoRecords title={null} description="There are no any saved routes" />
      ) : (
        <div className="plan-your-trip-my-routes-card-list">
          {/*begin::Item*/}
          {routes.map((route, index) => (
            <div
              key={route.id || index}
              className="plan-your-trip-my-routes-card-list-item"
            >
              <div className="plan-your-trip-my-routes-card-list-item-title">
                {route.name}
              </div>
              {/*begin::Details*/}
              <div className="plan-your-trip-my-routes-card-list-item-details">
                <div className="plan-your-trip-my-routes-card-list-item-details-item">
                  <Icon name="icon-pin" />
                  <span>{route.points.length} Point</span>
                </div>
                {!isEmpty(route.details?.distance) && (
                  <div className="plan-your-trip-my-routes-card-list-item-details-item">
                    <Icon name="icon-distance" />
                    <span>{getShortDistance(route.details?.distance)}</span>
                  </div>
                )}
                {!isEmpty(route.details?.duration) && (
                  <div className="plan-your-trip-my-routes-card-list-item-details-item">
                    <Icon name="icon-clock-2" />
                    <span>{getShortDuration(route.details?.duration)}</span>
                  </div>
                )}
                <div className="plan-your-trip-my-routes-card-list-item-details-item active">
                  {(() => {
                    switch (route.mode) {
                      case 'DRIVING':
                      default:
                        return <Icon name="icon-car" />;
                      case 'WALKING':
                        return <Icon name="icon-walk" />;
                      case 'TRANSIT':
                        return <Icon name="icon-bus" />;
                      case 'BICYCLING':
                        return <Icon name="icon-bicycle" />;
                    }
                  })()}
                </div>
              </div>
              {/*begin::Details*/}
              <div className="plan-your-trip-my-routes-card-list-item-actions">
                <button
                  className="plan-your-trip-my-routes-card-list-item-actions-button"
                  onClick={handleClickViewBind(route)}
                >
                  View
                </button>
                <button
                  className="plan-your-trip-my-routes-card-list-item-actions-button"
                  onClick={handleClickEditBind(route)}
                >
                  Edit Route
                </button>
                <button
                  className="plan-your-trip-my-routes-card-list-item-actions-button plan-your-trip-my-routes-card-list-item-actions-button--danger"
                  onClick={handleClickDeleteBind(route)}
                >
                  Delete
                </button>
                {/*begin::Route Delete Modal*/}
                {isDeleting && (
                  <PYTRouteDeleteModal
                    onClose={handleDeleteModalClose}
                    onCallback={handleDeleteModalCallback}
                  />
                )}
                {/*end::Route Delete Modal*/}
              </div>
            </div>
          ))}
          {/*end::Item*/}
        </div>
      )}
    </div>
  );
};

export default PlanYourTripMyRoutesCard;
