import React from 'react';
import { IPointMarkerTooltip } from '@/components/widgets/plan-your-trip/components/base/point-marker/tooltip/types';
import { isEmpty } from 'lodash';
import { clsx } from '@/utils';

const PointMarkerTooltip: React.FC<IPointMarkerTooltip> = ({
  text,
  variant,
}) => {
  if (isEmpty(text)) {
    return null;
  }

  return (
    <div
      className={clsx('google-map-tooltip', {
        [`google-map-tooltip--${variant}-variant`]: variant,
      })}
    >
      {text}
    </div>
  );
};

export default PointMarkerTooltip;
