import React, { useLayoutEffect, useState } from 'react';
import Icon from '@/components/base/icon';
import Portal from '@/components/base/portal';
import { IPYTRouteDeleteModal } from '@/components/widgets/plan-your-trip/components/modals/route-delete/types';
import { motion } from 'framer-motion';

const animations = {
  fade: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
  card: {
    hidden: {
      opacity: 0,
      y: -100,
    },
    show: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const PYTRouteDeleteModal: React.FC<IPYTRouteDeleteModal> = ({
  onClose: handleClose,
  onCallback: handleCallback,
}) => {
  // states
  const [isClosing, setClosing] = useState<boolean>(false);

  // actions
  const onClose = () => {
    setClosing(true);
    setTimeout(() => {
      handleClose && handleClose();
    }, 300);
  };

  const onCallback = () => {
    handleCallback && handleCallback();
  };

  // effects
  // @TODO: this use is bad. bounces on the site
  // useEffect(() => {
  //   document.body.style.overflow = 'hidden';

  //   return function cleanup() {
  //     document.body.style.overflow = '';
  //   };
  // }, []);

  useLayoutEffect(() => {
    const handleEscKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    window.addEventListener('keydown', handleEscKey);
    return () => window.removeEventListener('keydown', handleEscKey);
  }, []);

  return (
    <Portal>
      <motion.div
        variants={animations.fade}
        initial="hidden"
        animate={isClosing ? 'hidden' : 'show'}
        className="pyt-modal-backdrop"
        onClick={onClose}
      />
      <motion.div
        variants={animations.fade}
        initial="hidden"
        animate={isClosing ? 'hidden' : 'show'}
        className="pyt-modal pyt-modal--danger"
      >
        <div className="pyt-modal-container">
          {/*begin::Card*/}
          <motion.div
            variants={animations.card}
            initial="hidden"
            animate={isClosing ? 'hidden' : 'show'}
            className="pyt-modal-card"
          >
            {/*begin::Close*/}
            <button className="pyt-modal-close-btn" onClick={onClose}>
              <Icon name="icon-close" />
            </button>
            {/*end::Close*/}

            {/*begin::Title*/}
            <div className="pyt-modal-card-title">
              Are you sure you want to delete this route?
            </div>
            {/*end::Title*/}

            {/*begin::Description*/}
            <div className="pyt-modal-card-description pyt-modal-card-description--danger">
              Once deleted, this route cannot be recovered.
            </div>
            {/*end::Description*/}

            {/*begin::Actions*/}
            <div className="pyt-modal-card-actions">
              <button
                className="pyt-modal-card-actions-button"
                onClick={onClose}
              >
                Cancel
              </button>
              <button
                className="pyt-modal-card-actions-button pyt-modal-card-actions-button--primary"
                onClick={onCallback}
              >
                Yes, Delete Route
              </button>
            </div>
            {/*end::Actions*/}
          </motion.div>
          {/*end::Card*/}
        </div>
      </motion.div>
    </Portal>
  );
};

export default PYTRouteDeleteModal;
