import {
  startTransition,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ClearActionMeta, SingleValue } from 'react-select';
import { useApp } from '@wap-client/core';
import Icon from '@/components/base/icon';
import PlanYourTripSelectInput from '@/components/widgets/plan-your-trip/components/layout/filters/select-input/SelectInput';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import PlanYourTripFiltersAdvancedFiltersOverlay from '@/components/widgets/plan-your-trip/components/layout/filters/advanced-filters-overlay/AdvancedFiltersOverlay';
import { motion } from 'framer-motion';
import { queryService } from '@wap-client/services';
import { dataToSelectOptions } from '@/utils/base/selectoptions';
import { filter, isEmpty, isNil, isNull, throttle } from 'lodash';
import useDebouncedEffect from '@/hooks/useDebouncedEffect';
import { OptionType } from '@/utils/types';
import { PoiProps } from '@/components/widgets/plan-your-trip/types';
import {
  InputDataProps,
  InputsState,
} from '@/components/widgets/plan-your-trip/components/layout/filters/types';
import { clsx } from '@/utils';
import useDeviceType from '@/hooks/useDeviceType';
import PlanYourTripFiltersMobileRouteActions from '@/components/widgets/plan-your-trip/components/layout/filters/mobile-route-actions';
import PYTRouteDeleteModal from '../../modals/route-delete/RouteDeleteModal';
import { useRouter } from 'next/router';

const animations = {
  item: {
    hidden: {
      opacity: 0,
      x: -50,
      scale: 0.5,
    },
    show: {
      opacity: 1,
      x: 0,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const PlanYourTripFilters: React.FC = () => {
  const app = useApp();
  const router = useRouter();
  const deviceType = useDeviceType();
  const {
    selectedOriginOption,
    setSelectedOriginOption,
    selectedDestinationOption,
    setSelectedDestinationOption,
    showAdvancedFilters,
    setShowAdvancedFilters,
    resetSelectedPoints,
    editingUserRouteKey,
    currentRoute,
    deleteRouteById,
  } = useContext(PlanYourTripsContext);

  // refs
  const originSelectRef = useRef<any>(null);
  const destinationSelectRef = useRef<any>(null);

  // states
  const [isDeleting, setDeleting] = useState<boolean>(false);
  const [inputs, setInputs] = useState<InputsState>({
    origin: {
      isLoading: false,
      search: '',
      pageIndex: 0,
      selectOptions: [],
      selectedOption: selectedOriginOption,
    },
    destination: {
      isLoading: false,
      search: '',
      pageIndex: 0,
      selectOptions: [],
      selectedOption: selectedDestinationOption,
    },
  });

  // actions
  const getInput = (name: string): InputDataProps | null => {
    return inputs[name] || null;
  };

  const updateInput = (name: string, data: Partial<InputDataProps>) => {
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: {
        ...prevInputs[name],
        ...data,
      },
    }));
  };

  const onInputChangeBind = (name: string) => (searchText: string) => {
    updateInput(name, {
      pageIndex: 0,
      isLoadMoreDone: false,
      search: searchText,
    });

    if (isEmpty(searchText)) {
      // updateInput(name, {
      //   pageIndex: 0,
      //   isLoadMoreDone: false,
      //   selectOptions: [],
      // });
    } else if (searchText.length >= 3) {
      startTransition(() => {
        updateInput(name, {
          isLoading: true,
        });
      });
    }
  };

  const onChangeOrigin = (
    option: SingleValue<OptionType>,
    meta: ClearActionMeta<OptionType>
  ) => {
    if (meta.action === 'clear') {
      updateInput('origin', {
        selectedOption: null,
        selectOptions: [],
      });
    } else {
      updateInput('origin', {
        selectedOption: option,
      });
    }

    if (inputs.destination.selectedOption === null) {
      setTimeout(() => destinationSelectRef.current?.focus());
    }
  };

  const onChangeDestination = (
    option: SingleValue<OptionType>,
    meta: ClearActionMeta<OptionType>
  ) => {
    if (meta.action === 'clear') {
      updateInput('destination', {
        selectedOption: null,
        selectOptions: [],
      });
    } else {
      updateInput('destination', {
        selectedOption: option,
      });
    }

    if (inputs.origin.selectedOption === null) {
      setTimeout(() => destinationSelectRef.current?.focus());
    }
  };

  const onChangeSwitch = () => {
    if (inputs.origin.selectedOption) {
      updateInput('destination', {
        selectedOption: inputs.origin.selectedOption,
      });
    } else {
      updateInput('destination', {
        selectedOption: null,
      });
    }

    if (inputs.destination.selectedOption) {
      updateInput('origin', {
        selectedOption: inputs.destination.selectedOption,
      });
    } else {
      updateInput('origin', {
        selectedOption: null,
      });
    }
  };

  const handleSearch = () => {
    resetSelectedPoints();
    setSelectedOriginOption(inputs.origin.selectedOption);
    setSelectedDestinationOption(inputs.destination.selectedOption);
  };

  // const onToggleMapDarkMode = () => {
  //   setDarkMap((prevStatus) => !prevStatus);
  // };

  const fetchPoiList = async (input: InputDataProps) => {
    const searchParams = new URLSearchParams();
    if (!isEmpty(input.search)) {
      searchParams.append('search', String(input.search));
      searchParams.append('pageIndex', String(input.pageIndex));
      searchParams.append('pageSize', '50');
    }

    try {
      const response = await queryService.run<any>(app.environment, {
        name: 'plan-your-trip-poi-list',
        language: app.language,
        query: searchParams.toString(),
      });

      if (response) {
        return response.data;
      }
    } catch (err) {
      console.error('Error:', err);
      return null;
    }
  };

  const loadPoisSelectOptions = async (
    name: string,
    options: { loadmore?: boolean } = {}
  ) => {
    const input = getInput(name);

    // if you do not run these then
    if (
      !input ||
      (options.loadmore && input.isLoadMoreDone) ||
      input.search.length < 3
    ) {
      return;
    }

    startTransition(() => {
      updateInput(name, {
        isLoading: true,
      });
    });

    const mutatedInput = {
      ...input,
    };

    // set loadmore
    if (options.loadmore) {
      mutatedInput.pageIndex = input.pageIndex + 1;
      updateInput(name, {
        pageIndex: mutatedInput.pageIndex,
      });
    }

    // fetch poi list
    const pois = await fetchPoiList(mutatedInput);
    const filteredPois = ((pois: PoiProps[]) => {
      return filter(
        pois,
        (poi: PoiProps) => !isNil(poi.id) && !isNil(poi.lat) && !isNil(poi.lat)
      );
    })(pois);

    if (isEmpty(pois)) {
      updateInput(name, {
        isLoadMoreDone: true,
      });
    }

    if (filteredPois && !isEmpty(filteredPois)) {
      // if there are pois
      // convert pois data to select options data
      const newSelectOptions =
        dataToSelectOptions(filteredPois, {
          labelProp: 'title',
          valueProp: 'id',
        }) || [];

      if (options.loadmore) {
        // load more
        updateInput(name, {
          selectOptions: [...input.selectOptions, ...newSelectOptions],
        });
      } else {
        // first load
        updateInput(name, {
          selectOptions: newSelectOptions,
        });
      }
    }

    updateInput(name, {
      isLoading: false,
    });
  };

  const onOriginMenuScrollToBottom = () => {
    loadPoisSelectOptions('origin', {
      loadmore: true,
    });
  };

  const onDestinationMenuScrollToBottom = () => {
    loadPoisSelectOptions('destination', {
      loadmore: true,
    });
  };

  const handleToggleShowAdvancedFilters = () => {
    setShowAdvancedFilters(!showAdvancedFilters);
  };

  const handleClickSearchButton = useCallback(
    throttle(() => {
      handleSearch();
    }, 500),
    [inputs]
  );

  const handleClickDeleteCurrentRoute = () => {
    setDeleting(true);
  };

  const handleDeleteModalClose = () => {
    setDeleting(false);
  };

  const handleDeleteModalCallback = () => {
    if (currentRoute) {
      deleteRouteById(currentRoute.id);
      router.push('/auth/my-routes');
    }

    handleDeleteModalClose();
  };

  // effects
  useDebouncedEffect(
    () => {
      loadPoisSelectOptions('origin');
    },
    [inputs.origin.search],
    300
  );

  useDebouncedEffect(
    () => {
      loadPoisSelectOptions('destination');
    },
    [inputs.destination.search],
    200
  );

  useDebouncedEffect(
    () => {
      // if the device is "mobile" then search it directly without clicking search btn
      if (deviceType === 'mobile') {
        handleSearch();
      }
    },
    [inputs.destination.selectedOption, inputs.origin.selectedOption],
    200
  );

  useEffect(() => {
    // if origin not changed then do nothing
    if (selectedOriginOption?.value === inputs.origin.selectedOption?.value) {
      return;
    }

    updateInput('origin', {
      selectedOption: selectedOriginOption,
    });
  }, [selectedOriginOption]);

  useEffect(() => {
    // if destination not changed then do nothing
    if (
      selectedDestinationOption?.value ===
      inputs.destination.selectedOption?.value
    ) {
      return;
    }

    updateInput('destination', {
      selectedOption: selectedDestinationOption,
    });
  }, [selectedDestinationOption]);

  return (
    <div className="plan-your-trip-filters">
      <div className="plan-your-trip-filters-input-group-wrapper">
        <motion.div
          className="plan-your-trip-filters-input-group"
          variants={animations.item}
        >
          <label
            className={clsx('plan-your-trip-filters-input', {
              active: !isEmpty(inputs.origin.selectedOption),
            })}
          >
            <Icon name="icon-pin-v2" />
            <PlanYourTripSelectInput
              ref={originSelectRef}
              options={inputs.origin.selectOptions}
              placeholder="Where are you starting?"
              isLoading={inputs.origin.isLoading}
              isDisabled={!isNull(editingUserRouteKey)}
              noOptionsMessage={() =>
                !inputs.origin.isLoading &&
                inputs.origin.search.length >= 3 &&
                isEmpty(inputs.origin.selectOptions)
                  ? 'The place you are looking for is not found!'
                  : null
              }
              onMenuScrollToBottom={
                !inputs.origin.isLoadMoreDone && onOriginMenuScrollToBottom
              }
              onInputChange={onInputChangeBind('origin')}
              onChange={onChangeOrigin}
              inputValue={inputs.origin.search}
              value={inputs.origin.selectedOption}
            />
          </label>
        </motion.div>
        <motion.button
          role="button"
          className="plan-your-trip-filters-input-group-switch"
          disabled={!isNull(editingUserRouteKey)}
          variants={animations.item}
          onClick={onChangeSwitch}
        >
          <Icon name="icon-switch" />
          {/* <Icon name="icon-arrow-up-down" /> */}
        </motion.button>
        <motion.div
          className="plan-your-trip-filters-input-group"
          variants={animations.item}
        >
          <label
            className={clsx('plan-your-trip-filters-input', {
              active: !isEmpty(inputs.destination.selectedOption),
            })}
          >
            <Icon name="icon-pin-v2" />
            <PlanYourTripSelectInput
              ref={destinationSelectRef}
              options={inputs.destination.selectOptions}
              placeholder="Where are you going?"
              isLoading={inputs.destination.isLoading}
              isDisabled={!isNull(editingUserRouteKey)}
              noOptionsMessage={() =>
                !inputs.destination.isLoading &&
                inputs.destination.search.length >= 3 &&
                isEmpty(inputs.destination.selectOptions)
                  ? 'The place you are looking for is not found!'
                  : null
              }
              onMenuScrollToBottom={
                !inputs.destination.isLoadMoreDone &&
                onDestinationMenuScrollToBottom
              }
              onInputChange={onInputChangeBind('destination')}
              onChange={onChangeDestination}
              inputValue={inputs.destination.search}
              value={inputs.destination.selectedOption}
            />
          </label>
        </motion.div>
      </div>
      <div className="plan-your-trip-filters-toolbar">
        {deviceType !== 'mobile' && isNull(editingUserRouteKey) && (
          <div className="plan-your-trip-filters-toolbar-item">
            <motion.button
              className="plan-your-trip-filters-toolbar-button"
              variants={animations.item}
              onClick={handleClickSearchButton}
            >
              <Icon name="icon-search-menu" />
              Search
            </motion.button>
          </div>
        )}
        {isNull(editingUserRouteKey) && (
          <div className="plan-your-trip-filters-toolbar-item">
            <motion.button
              className={clsx('plan-your-trip-filters-toolbar-button', {
                active: showAdvancedFilters,
              })}
              variants={animations.item}
              onClick={handleToggleShowAdvancedFilters}
            >
              <Icon name="icon-menu-circle-outline" />
              Filter
              {showAdvancedFilters && <Icon name="icon-chev-up" />}
            </motion.button>
          </div>
        )}
        {!isEmpty(editingUserRouteKey) && (
          <div className="plan-your-trip-filters-toolbar-item plan-your-trip-filters-toolbar-item--stick-right">
            <motion.button
              className="plan-your-trip-filters-toolbar-button plan-your-trip-filters-toolbar-button--danger"
              variants={animations.item}
              onClick={handleClickDeleteCurrentRoute}
            >
              <Icon name="icon-close" />
              Delete
            </motion.button>
            {/*begin::Route Delete Modal*/}
            {isDeleting && (
              <PYTRouteDeleteModal
                onClose={handleDeleteModalClose}
                onCallback={handleDeleteModalCallback}
              />
            )}
            {/*end::Route Delete Modal*/}
          </div>
        )}
      </div>
      {deviceType === 'mobile' && <PlanYourTripFiltersMobileRouteActions />}
      {showAdvancedFilters && (
        <PlanYourTripFiltersAdvancedFiltersOverlay
          onClose={handleToggleShowAdvancedFilters}
        />
      )}
    </div>
  );
};

export default PlanYourTripFilters;
