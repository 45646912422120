import { useContext } from 'react';
import Icon from '@/components/base/icon';
import { clsx } from '@/utils';
import { IPlanYourTripPointListToolbarProps } from '@/components/widgets/plan-your-trip/components/layout/point-list/toolbar/types';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import { isEmpty, isNil, isNull } from 'lodash';
import {
  getShortDistance,
  getShortDuration,
} from '@/components/base/google-map/utils';
import { GoogleMapContext } from '@/components/base/google-map/Context';
import { ITravelMode } from '@/components/base/google-map/types';
import Anchor from '@/components/base/anchor/Anchor';

const PlanYourTripPointListToolbar: React.FC<
  IPlanYourTripPointListToolbarProps
> = ({ points = [] }) => {
  const { directions, travelMode, setTravelMode } =
    useContext(GoogleMapContext);
  const {
    nearyRoute,
    setShowCurrentRouteSummaryCard,
    setShowMyRoutes,
    selectedRoutePoints,
    isEditing,
    setEditing,
    resetCurrentRoute,
    resetSelectedPoints,
    editingUserRouteKey,
  } = useContext(PlanYourTripsContext);

  // actions
  const handleClickSaveRoute = () => {
    setShowMyRoutes(false);
    setShowCurrentRouteSummaryCard(true);
  };

  const handleClickTravelModeBind = (mode: ITravelMode) => () => {
    setTravelMode(mode);
  };

  const handleClickExitEditing = () => {
    resetCurrentRoute();
    resetSelectedPoints();
    setEditing(false);
    setShowCurrentRouteSummaryCard(false);
  };

  return (
    <div className="google-map-point-list-toolbar">
      {/*begin::Actions*/}
      <div className="google-map-point-list-toolbar-actions">
        <div className="google-map-point-list-toolbar-group">
          {isEditing || !isEmpty(editingUserRouteKey) ? (
            <>
              {!isEmpty(editingUserRouteKey) ? (
                <Anchor
                  href="/auth/my-profile"
                  className="google-map-point-list-toolbar-button"
                >
                  <Icon name="icon-arrow-right-outline" reverse />
                  <span>My Profile</span>
                </Anchor>
              ) : (
                <button
                  className={clsx(
                    'google-map-point-list-toolbar-button google-map-point-list-toolbar-button--hero'
                  )}
                  onClick={handleClickExitEditing}
                >
                  <Icon name="icon-arrow-right-outline" reverse />
                  <span>Full Route</span>
                </button>
              )}
            </>
          ) : (
            <button
              className={clsx(
                'google-map-point-list-toolbar-button google-map-point-list-toolbar-button--hero',
                {
                  'google-map-point-list-toolbar-button--hero-reversed':
                    selectedRoutePoints.length >= 2,
                }
              )}
            >
              <div className="google-map-point-list-toolbar-button-badge">
                {selectedRoutePoints.length >= 2 ? (
                  <span>{selectedRoutePoints.length}</span>
                ) : (
                  <span>{points.length}</span>
                )}
              </div>
              {selectedRoutePoints.length >= 2 ? (
                <span>View route</span>
              ) : (
                <span>Point</span>
              )}
            </button>
          )}
        </div>
        <div className="google-map-point-list-toolbar-group">
          <button
            className={clsx(
              'google-map-point-list-toolbar-button google-map-point-list-toolbar-button--save',
              {
                'google-map-point-list-toolbar-button--save--active':
                  !isEmpty(editingUserRouteKey),
              }
            )}
            onClick={handleClickSaveRoute}
          >
            <Icon name="icon-pin-save" />
            <span>
              {!isEmpty(editingUserRouteKey) ? 'Save Changes' : 'Save Route'}
            </span>
          </button>
          {isNull(editingUserRouteKey) && (
            <div className="google-map-point-list-toolbar-bracket" />
          )}
          <button className="google-map-point-list-toolbar-button google-map-point-list-toolbar-button--share">
            <Icon name="icon-share-2" />
          </button>
        </div>
      </div>
      {/*end::Actions*/}
      {/*begin::Route Info*/}
      {!isNil(directions) && (
        <div className="google-map-point-list-toolbar-route-info">
          <div className="google-map-point-list-toolbar-route-info-travel-modes">
            <button
              className={clsx(
                'google-map-point-list-toolbar-route-info-travel-modes-item',
                {
                  active: travelMode === 'DRIVING',
                }
              )}
              title="By Car"
              onClick={handleClickTravelModeBind('DRIVING')}
            >
              <Icon name="icon-car" />
            </button>
            <button
              className={clsx(
                'google-map-point-list-toolbar-route-info-travel-modes-item',
                {
                  active: travelMode === 'TRANSIT',
                }
              )}
              title="Public Transport"
              onClick={handleClickTravelModeBind('TRANSIT')}
            >
              <Icon name="icon-bus" />
            </button>
            <button
              className={clsx(
                'google-map-point-list-toolbar-route-info-travel-modes-item',
                {
                  active: travelMode === 'WALKING',
                }
              )}
              title="On Foot"
              onClick={handleClickTravelModeBind('WALKING')}
            >
              <Icon name="icon-walk" />
            </button>
          </div>
          {!isNil(nearyRoute.details?.distance) &&
            !isNil(nearyRoute.details.duration) && (
              <>
                <div className="google-map-point-list-toolbar-route-info-bracket"></div>
                <div className="google-map-point-list-toolbar-route-info-details">
                  <div
                    className="google-map-point-list-toolbar-route-info-details-item"
                    title={nearyRoute.details?.distance}
                  >
                    <Icon name="icon-distance" />
                    <span>
                      {getShortDistance(nearyRoute.details?.distance)}
                    </span>
                  </div>
                  <div className="google-map-point-list-toolbar-bracket" />
                  <div
                    className="google-map-point-list-toolbar-route-info-details-item"
                    title={nearyRoute.details?.duration}
                  >
                    <Icon name="icon-clock-2" />
                    <span>
                      {getShortDuration(nearyRoute.details?.duration)}
                    </span>
                  </div>
                </div>
              </>
            )}
        </div>
      )}
      {/*end::Route Info*/}
    </div>
  );
};

export default PlanYourTripPointListToolbar;
