import { useState } from 'react';
import { Container } from '@/components/base/gridview';
import { IPlanYourTripsProps } from '@/components/widgets/plan-your-trip/types';
import PlanYourTripFilters from '@/components/widgets/plan-your-trip/components/layout/filters/Filters';
import PlanYourTripMap from '@/components/widgets/plan-your-trip/components/layout/map/Map';
// import PlanYourTripToolbar from '@/components/widgets/plan-your-trip/toolbar/Toolbar';
import PlanYourTripsContextProvider from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import GoogleMapContextProvider from '@/components/base/google-map/Context';
import { InView } from 'react-intersection-observer';

const PlanYourTrip: React.FC<IPlanYourTripsProps> = () => {
  const [isInView, setInView] = useState<boolean>(false);

  // actions
  const onChangeInView = (status: boolean) => {
    if (isInView) {
      return;
    }

    setInView(status);
  };

  // renders
  if (!isInView) {
    // @NOTE: this is for laz* lazy load for PYT modules
    return (
      <>
        <InView rootMargin="50px 0px" onChange={onChangeInView} />
        <div
          className="plan-your-trip plan-your-trip--placeholder"
          id="plan-your-trip"
        >
          <Container size="wide">
            <PlanYourTripFilters />
            <div className="google-map" />
          </Container>
        </div>
      </>
    );
  }

  return (
    <GoogleMapContextProvider>
      <PlanYourTripsContextProvider>
        <div className="plan-your-trip" id="plan-your-trip">
          <Container size="wide">
            <PlanYourTripFilters />
            <PlanYourTripMap />
          </Container>
        </div>
      </PlanYourTripsContextProvider>
    </GoogleMapContextProvider>
  );
};

export default PlanYourTrip;
