import React, { useContext, useMemo, useState } from 'react';
import { MarkerF, InfoBox, OverlayView } from '@react-google-maps/api';
import { InfoBoxOptions } from '@react-google-maps/infobox';
import { IPointMarker } from '@/widgets/plan-your-trip/components/base/point-marker/types';
import PointMarkerPopup from '@/widgets/plan-your-trip/components/base/point-marker/popup/Popup';
import { isEmpty, isNil } from 'lodash';
import { PointProps } from '@/components/widgets/plan-your-trip/types';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import { clsx } from '@/utils';
import PointMarkerTooltip from '@/components/widgets/plan-your-trip/components/base/point-marker/tooltip/Tooltip';

const PointMarker: React.FC<IPointMarker> = ({
  point,
  label,
  icon,
  order,
  showTooltip = false,
  hoverable = true,
  clusterer,
  showInfoBox,
  onInfoBoxShow,
  onInfoBoxHide,
  variant = 'circle',
}) => {
  const { isPointSelected, togglePointAddOrRemove } =
    useContext(PlanYourTripsContext);

  // states
  const [hovered, setHovered] = useState(false);

  // data
  const isSelected = isPointSelected(point);
  const position = useMemo(() => {
    return {
      lat: point.lat,
      lng: point.lng,
    };
  }, [point]);

  const iconOptions = useMemo<google.maps.Icon>(() => {
    // variant: pin
    if (variant === 'pin') {
      return {
        url: `/images/map/marker/marker-${variant}-${
          isSelected ? 'active' : hovered ? 'hover' : 'default'
        }.svg`,
        anchor: new window.google.maps.Point(15, 21 + 22),
        scaledSize: new window.google.maps.Size(30, 42),
        labelOrigin: new window.google.maps.Point(11, -20),
        ...icon,
      };
    }

    // variant: circle
    return {
      url: `/images/map/marker/marker-circle-${
        isSelected ? 'active' : hovered ? 'hover' : 'default'
      }.svg`,
      anchor: new window.google.maps.Point(23, 23),
      scaledSize: new window.google.maps.Size(46, 46),
      labelOrigin: new window.google.maps.Point(11, -20),
      ...icon,
    };
  }, [icon, variant, isSelected, showInfoBox, hovered]);

  const labelOptions = useMemo<google.maps.MarkerLabel | undefined>(() => {
    // if (!showInfoBox && label && !isEmpty(label)) {
    //   return {
    //     className: clsx('google-map-pin-label', {
    //       'google-map-pin-label--pin-variant': variant === 'pin',
    //     }),
    //     text: label,
    //   };
    // }

    if (order) {
      return {
        className: clsx('google-map-pin-order', {
          'google-map-pin-order--pin-variant': variant === 'pin',
        }),
        text: String(order),
      };
    }

    return {
      className: 'google-map-pin-hidden',
      text: ' ',
    };
  }, [label, point, hovered, variant, order, showInfoBox]);

  const popupInfoBoxOptions: InfoBoxOptions = useMemo(() => {
    const isPin = variant === 'pin';
    return {
      boxStyle: { overflow: 'unset' },
      closeBoxURL: '',
      maxWidth: 184,
      infoBoxClearance: new window.google.maps.Size(40, 40),
      pixelOffset: new window.google.maps.Size(
        -(184 / 2),
        isPin ? -(19 + 22) : -15
      ),
      alignBottom: true,
      visible: true,
    };
  }, [variant]);

  // actions
  const onCloseClickInfoView = () => {
    onInfoBoxHide && onInfoBoxHide();
  };

  const handleTogglePointAddToRoute = (point: PointProps) => {
    if (!point) {
      return;
    }

    togglePointAddOrRemove(point);
  };

  const onClickMarkerBind = (point: PointProps) => () => {
    // if already opened then close the popup
    if (showInfoBox) {
      onCloseClickInfoView();
      return;
    }

    // if the title of point has set then show the popup
    if (point && !isEmpty(point.title)) {
      onInfoBoxShow && onInfoBoxShow(point);
    }
  };

  const onMouseHover = () => {
    if (!hoverable) return;
    setHovered(true);
  };

  const onMouseOut = () => {
    if (!hoverable) return;
    setHovered(false);
  };

  return (
    <MarkerF
      position={position}
      icon={iconOptions}
      clusterer={clusterer}
      label={labelOptions}
      onMouseOver={onMouseHover}
      onMouseOut={onMouseOut}
      onClick={onClickMarkerBind(point)}
    >
      {/*begin::Tooltip*/}
      {!isNil(point.title) && !showInfoBox && (showTooltip || hovered) && (
        <OverlayView
          position={{
            lat: point.lat,
            lng: point.lng,
          }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <PointMarkerTooltip text={point.title} variant={variant} />
        </OverlayView>
      )}
      {/*end::Tooltip*/}
      {/*begin::Popup*/}
      {showInfoBox && (
        <InfoBox
          options={popupInfoBoxOptions}
          onCloseClick={onCloseClickInfoView}
        >
          <PointMarkerPopup
            data={point}
            onToggleAddRoute={handleTogglePointAddToRoute}
            onClose={onCloseClickInfoView}
          />
        </InfoBox>
      )}
      {/*end::Popup*/}
    </MarkerF>
  );
};

export default PointMarker;
