import Icon from '@/components/base/icon';
import React, { useContext } from 'react';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import { clsx } from '@/utils';

const PlanYourTripMyRoutesStickyButton: React.FC = () => {
  const { showMyRoutes, setShowMyRoutes } = useContext(PlanYourTripsContext);

  // actions
  const onClick = () => {
    setShowMyRoutes((prevStatus) => !prevStatus);
  };

  return (
    <div
      className={clsx('plan-your-trip-my-routes-sticky-button', {
        active: showMyRoutes,
      })}
      onClick={onClick}
    >
      <div className="plan-your-trip-my-routes-sticky-button-shape">
        <img src="/images/map/my-routes-shape.svg" />
      </div>
      <div className="plan-your-trip-my-routes-sticky-button-text">
        <Icon name="icon-build-route" />
        My Routes
      </div>
    </div>
  );
};

export default PlanYourTripMyRoutesStickyButton;
