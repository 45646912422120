import React, { useContext, useState } from 'react';
import { MarkerClusterer } from '@react-google-maps/api';
import { IPlanYourTripAdvancedMarkerClustererProps } from '@/components/widgets/plan-your-trip/components/base/advanced-clusterer/types';
import PointMarker from '@/components/widgets/plan-your-trip/components/base/point-marker';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import { PointProps } from '@/components/widgets/plan-your-trip/types';
import { isEmpty, isNil } from 'lodash';

const renderClustererText = (markers: any[], num: number) => {
  const index = Math.min(markers.length, num);
  const size = markers.length > 999 ? 999 : markers.length;
  const prefixClass = size > 999 ? 'large' : size > 99 ? 'medium' : 'small';
  return {
    text: `${size}`,
    index,
    html: `<div class='google-map-pin-clusterer-text ${prefixClass}-digits'>${size}</div>`,
  };
};

const PlanYourTripAdvancedMarkerClusterer: React.FC<
  IPlanYourTripAdvancedMarkerClustererProps
> = ({ points, options, showTooltips, variant, ...props }) => {
  const { getSelectedPointOrder } = useContext(PlanYourTripsContext);

  // states
  const [selectedPointId, setSelectedPointId] = useState<string | null>(null);

  // actions
  const onInfoBoxShow = (point: PointProps) => {
    setSelectedPointId(point.id);
  };

  const onInfoBoxHide = () => {
    setSelectedPointId(null);
  };

  if (isNil(points) || isEmpty(points)) {
    return null;
  }

  return (
    <MarkerClusterer
      options={{
        clusterClass: 'google-map-pin-clusterer',
        enableRetinaIcons: false,
        minimumClusterSize: 2,
        averageCenter: true,
        maxZoom: 17,
        calculator: renderClustererText,
        styles: [
          {
            url: '/images/map/clusterer/default.svg',
            height: 34,
            width: 34,
          },
        ],
        ...options,
      }}
      {...props}
    >
      {(clusterer) => (
        <>
          {points.map((point: PointProps) => (
            <PointMarker
              key={point.id}
              variant={variant}
              order={getSelectedPointOrder(point)}
              point={point}
              clusterer={clusterer}
              showTooltip={showTooltips}
              showInfoBox={selectedPointId === point.id}
              onInfoBoxShow={onInfoBoxShow}
              onInfoBoxHide={onInfoBoxHide}
            />
          ))}
        </>
      )}
    </MarkerClusterer>
  );
};

export default PlanYourTripAdvancedMarkerClusterer;
