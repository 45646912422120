import { isPlainObject } from 'lodash';
import { OptionType } from '../types';

interface IOptions {
  labelProp: string | number;
  valueProp: string | number;
}

export const dataToSelectOption = (data: any, initialOptions?: IOptions) => {
  const options = {
    labelProp: initialOptions?.labelProp || 'title',
    valueProp: initialOptions?.valueProp || 'id',
  };

  if (!isPlainObject(data)) {
    return null;
  }

  return {
    rawData: data,
    label: data[options.labelProp],
    value: data[options.valueProp],
  };
};

export const dataToSelectOptions = (
  data: unknown[],
  initialOptions?: IOptions
) => {
  const options = {
    labelProp: initialOptions?.labelProp || 'title',
    valueProp: initialOptions?.valueProp || 'id',
  };

  if (!Array.isArray(data)) {
    return null;
  }

  return data.map((item: any) => dataToSelectOption(item, options));
};

export const selectOptionToData = (data?: OptionType | null) => {
  if (!data || !isPlainObject(data)) {
    return null;
  }

  return data.rawData;
};

export const selectOptionsToData = (data: OptionType[]) => {
  if (!Array.isArray(data)) {
    return null;
  }

  return data.map((item: any) => selectOptionToData(item));
};
