import React, { useContext } from 'react';
import Icon from '@/components/base/icon';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import { clsx } from '@/utils';
import { GoogleMapContext } from '@/components/base/google-map/Context';
import { isEmpty } from 'lodash';
import Anchor from '@/components/base/anchor/Anchor';

const PlanYourTripFiltersMobileRouteActions: React.FC = () => {
  const {
    nearyRoute,
    setShowCurrentRouteSummaryCard,
    setShowMyRoutes,
    selectedRoutePoints,
    isEditing,
    setEditing,
    resetCurrentRoute,
    resetSelectedPoints,
    editingUserRouteKey,
  } = useContext(PlanYourTripsContext);
  const { setShowPointList } = useContext(GoogleMapContext);

  // actions
  const handleClickSaveRoute = () => {
    setShowMyRoutes(false);
    setShowCurrentRouteSummaryCard(true);
  };

  const handleClickExitEditing = () => {
    resetCurrentRoute();
    resetSelectedPoints();
    setEditing(false);
    setShowCurrentRouteSummaryCard(false);
  };

  const handleClickPointsButton = () => {
    setShowPointList((prevStatus) => !prevStatus);
  };

  return (
    <div className="plan-your-trip-filters-route-actions">
      <div className="plan-your-trip-filters-route-actions-group">
        {isEditing || !isEmpty(editingUserRouteKey) ? (
          <>
            {!isEmpty(editingUserRouteKey) ? (
              <Anchor
                href="/auth/my-profile"
                className={clsx('plan-your-trip-filters-route-actions-button')}
              >
                <Icon name="icon-arrow-right-outline" reverse />
                <span>My Profile</span>
              </Anchor>
            ) : (
              <button
                className={clsx(
                  'plan-your-trip-filters-route-actions-button plan-your-trip-filters-route-actions-button--hero'
                )}
                onClick={handleClickExitEditing}
              >
                <Icon name="icon-arrow-right-outline" reverse />
                <span>Full Route</span>
              </button>
            )}
          </>
        ) : (
          <button
            className={clsx(
              'plan-your-trip-filters-route-actions-button plan-your-trip-filters-route-actions-button--hero',
              {
                'plan-your-trip-filters-route-actions-button--hero-reversed':
                  selectedRoutePoints.length >= 2,
              }
            )}
            onClick={handleClickPointsButton}
          >
            <div className="plan-your-trip-filters-route-actions-button-badge">
              {selectedRoutePoints.length >= 2 ? (
                <span>{selectedRoutePoints.length}</span>
              ) : (
                <span>{nearyRoute.points.length}</span>
              )}
            </div>
            {selectedRoutePoints.length >= 2 ? (
              <span>View route</span>
            ) : (
              <span>Point</span>
            )}
          </button>
        )}
      </div>
      <div className="plan-your-trip-filters-route-actions-group">
        <button
          className={clsx(
            'plan-your-trip-filters-route-actions-button plan-your-trip-filters-route-actions-button--save',
            {
              'plan-your-trip-filters-route-actions-button--save--active':
                !isEmpty(editingUserRouteKey),
            }
          )}
          onClick={handleClickSaveRoute}
        >
          <Icon name="icon-pin-save" />
          {!isEmpty(editingUserRouteKey) ? 'Save Changes' : 'Save Route'}
        </button>
        <div className="plan-your-trip-filters-route-actions-bracket" />
        <button className="plan-your-trip-filters-route-actions-button plan-your-trip-filters-route-actions-button--share">
          <Icon name="icon-share-2" />
        </button>
      </div>
    </div>
  );
};

export default PlanYourTripFiltersMobileRouteActions;
