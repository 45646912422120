import { useContext, useEffect, useMemo } from 'react';
import { filter, isEmpty } from 'lodash';
import { IPlanYourTripPointListProps } from '@/widgets/plan-your-trip/components/layout/point-list/types';
import PlanYourTripPointListItem from '@/widgets/plan-your-trip/components/layout/point-list/item';
import PlanYourTripPointListToolbar from '@/widgets/plan-your-trip/components/layout/point-list/toolbar';
import { PointProps } from '@/components/widgets/plan-your-trip/types';
import { motion } from 'framer-motion';
import { clsx, setStorage } from '@/utils';
import { ListViewModeType } from '@/components/base/google-map/types';
import { LOCAL_STORAGE_KEYS_GMAP } from '@/components/base/google-map/constants';
import Icon from '@/components/base/icon';
import NoRecords from '@/components/base/no-records';
import { GoogleMapContext } from '@/components/base/google-map/Context';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import useDeviceType from '@/hooks/useDeviceType';

const animations = {
  items: {
    hidden: {
      opacity: 0,
    },
    show: {
      opacity: 1,
      transition: {
        duration: 0.3,
        staggerChildren: 0.1,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
};

const PlanYourTripPointList: React.FC<IPlanYourTripPointListProps> = ({
  points,
}) => {
  const deviceType = useDeviceType();
  const { showPointList, listViewMode, setListViewMode, setShowPointList } =
    useContext(GoogleMapContext);
  const {
    selectedRoutePoints,
    togglePointAddOrRemove,
    resetSelectedPoints,
    editingUserRouteKey,
  } = useContext(PlanYourTripsContext);

  // data
  const filteredPoints = useMemo(
    () => filter(points, (point: any) => !isEmpty(point.title)),
    [points]
  );

  // actions
  const handleTogglePointAddToRoute = (point: PointProps) => {
    if (!point) {
      return;
    }

    togglePointAddOrRemove(point);
  };

  const onClickClear = () => {
    resetSelectedPoints();
  };

  const handleChangeListView = (mode: ListViewModeType) => () => {
    setListViewMode(mode);
    setStorage(LOCAL_STORAGE_KEYS_GMAP.listViewMode, mode);
  };

  const handleClickViewOnMap = () => {
    setShowPointList(false);
  };

  // effects
  useEffect(() => {
    const status = deviceType !== 'mobile';
    setShowPointList(status);
  }, [deviceType]);

  // renders
  if (!showPointList || isEmpty(filteredPoints)) {
    return null;
  }

  return (
    <div className="google-map-point-list">
      {deviceType !== 'mobile' && (
        <PlanYourTripPointListToolbar points={filteredPoints} />
      )}
      <div className="google-map-point-list-scrollable">
        {/*begin::Header*/}
        <header className="google-map-point-list-header">
          <div className="google-map-point-list-header-title">
            {isEmpty(editingUserRouteKey) ? (
              <span>Route points</span>
            ) : (
              <span>
                <strong>{selectedRoutePoints.length}</strong> My route points
              </span>
            )}
          </div>
          {selectedRoutePoints.length > 0 && (
            <button
              className="google-map-point-list-header-clear-button"
              onClick={onClickClear}
            >
              Clear
            </button>
          )}
          <div className="google-map-point-list-header-buttons">
            <button
              className={clsx('google-map-point-list-header-buttons-button', {
                active: listViewMode === 'detailed',
              })}
              onClick={handleChangeListView('detailed')}
            >
              <Icon name="icon-see-list" />
            </button>
            <button
              className={clsx('google-map-point-list-header-buttons-button', {
                active: listViewMode === 'compact',
              })}
              onClick={handleChangeListView('compact')}
            >
              <Icon name="icon-see-list-compact" />
            </button>
          </div>
        </header>
        {/*end::Header*/}
        {/*begin::Items*/}
        <motion.div
          variants={animations.items}
          className={clsx('google-map-point-list-items', {
            'google-map-point-list-items--detailed':
              listViewMode === 'detailed',
            'google-map-point-list-items--compact': listViewMode === 'compact',
          })}
        >
          {/*begin::Item*/}
          {filteredPoints.map((point: any, index) => (
            <PlanYourTripPointListItem
              key={index}
              point={point}
              onToggleAddRoute={handleTogglePointAddToRoute}
            />
          ))}
          {/*end::Item*/}
          {/*begin::NoRecords*/}
          {isEmpty(filteredPoints) && (
            <NoRecords
              title="My Route Points"
              description="There are no selected points on the route yet"
            />
          )}
          {/*end::NoRecords*/}
        </motion.div>
        {/*end::Items*/}
      </div>
      {deviceType === 'mobile' && (
        <button
          className="google-map-point-list-button"
          onClick={handleClickViewOnMap}
        >
          <Icon name="icon-view-map" />
          <span>View on map</span>
        </button>
      )}
    </div>
  );
};

export default PlanYourTripPointList;
