import { useEffect, RefObject } from 'react';

function useElOutsideActionAlerter(
  ref: RefObject<HTMLElement>,
  callback?: () => void
) {
  useEffect(() => {
    // alert if clicked on outside of element
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback && callback();
      }
    };

    // bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export default useElOutsideActionAlerter;
