import React, { PropsWithChildren, useRef } from 'react';
import ReactDOM from 'react-dom';

const Portal: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  const portal = document.createElement('div');
  const portalRef = useRef<HTMLDivElement>(portal);
  document.body.appendChild(portalRef.current);

  return ReactDOM.createPortal(children, portalRef.current);
};

export default Portal;
