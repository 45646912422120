import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { SingleValue } from 'react-select';
import useDebouncedEffect from '@/hooks/useDebouncedEffect';
import {
  compact,
  find,
  findIndex,
  first,
  isEmpty,
  isNil,
  isNull,
  map,
  reject,
  toLower,
  toPlainObject,
  unionBy,
  uniqBy,
} from 'lodash';
import { OptionType } from '@/utils/types';
import { queryService } from '@wap-client/services';
import { useApp } from '@wap-client/core';
import {
  NearyRouteProps,
  PointProps,
  PoiProps,
  RouteProps,
  StepProps,
} from '@/components/widgets/plan-your-trip/types';
import { GoogleMapContext } from '@/components/base/google-map/Context';
import { POINT as POINT_MAPPING } from '@/components/widgets/constants';
import { uid } from '@/utils/generator';
import { dataToSelectOption } from '@/utils/base/selectoptions';
import { useRouter } from 'next/router';
import { toCoordinatesNumber } from '@/utils/base/map';

export const PlanYourTripsContext = createContext<{
  selectedRoutePoints: PointProps[];
  routes: RouteProps[];
  setRoutes: React.Dispatch<React.SetStateAction<RouteProps[]>>;
  currentRoute: RouteProps;
  setCurrentRoute: React.Dispatch<React.SetStateAction<RouteProps>>;
  nearyRoute: NearyRouteProps;
  setNearyRoute: React.Dispatch<React.SetStateAction<NearyRouteProps>>;
  isEditing: boolean;
  setEditing: React.Dispatch<React.SetStateAction<boolean>>;
  editingUserRouteKey: string | null;
  isRouteLoading: boolean;
  selectedOriginOption: OptionType | null;
  setSelectedOriginOption: React.Dispatch<
    React.SetStateAction<OptionType | null>
  >;
  selectedDestinationOption: OptionType | null;
  setSelectedDestinationOption: React.Dispatch<
    React.SetStateAction<OptionType | null>
  >;
  showAdvancedFilters: boolean;
  setShowAdvancedFilters: React.Dispatch<React.SetStateAction<boolean>>;
  showCurrentRouteSummaryCard: boolean;
  setShowCurrentRouteSummaryCard: React.Dispatch<React.SetStateAction<boolean>>;
  showMyRoutes: boolean;
  setShowMyRoutes: React.Dispatch<React.SetStateAction<boolean>>;
  fetchPYTNearyRoute: () => void;
  resetCurrentRoute: () => void;
  saveCurrentRoute: (name: string) => RouteProps;
  changeCurrentRoute: (id: PoiProps['id']) => void;
  deleteRouteById: (id: PoiProps['id']) => void;
  isPointSelected: (point: PointProps) => boolean;
  getSelectedPointOrder: (point: PointProps) => number | null;
  addPoint: (point: PointProps) => void;
  removePoint: (point: PointProps) => void;
  resetSelectedPoints: () => void;
  togglePointAddOrRemove: (point: PointProps) => void;
}>({
  selectedRoutePoints: [],
  routes: [],
  setRoutes: () => {},
  currentRoute: {
    id: '',
    mode: 'DRIVING',
    points: [],
  },
  setCurrentRoute: () => {},
  nearyRoute: {
    points: [],
    steps: [],
    details: {
      distance: null,
      duration: null,
    },
  },
  setNearyRoute: () => {},
  isEditing: false,
  setEditing: () => {},
  editingUserRouteKey: null,
  isRouteLoading: false,
  selectedOriginOption: null,
  setSelectedOriginOption: () => {},
  selectedDestinationOption: null,
  setSelectedDestinationOption: () => {},
  showAdvancedFilters: false,
  setShowAdvancedFilters: () => {},
  showCurrentRouteSummaryCard: false,
  setShowCurrentRouteSummaryCard: () => {},
  showMyRoutes: false,
  setShowMyRoutes: () => {},
  fetchPYTNearyRoute: () => {},
  resetCurrentRoute: () => {},
  saveCurrentRoute: () => ({
    id: '',
    mode: 'DRIVING',
    points: [],
  }),
  changeCurrentRoute: () => {},
  deleteRouteById: () => {},
  isPointSelected: () => false,
  getSelectedPointOrder: () => null,
  addPoint: () => {},
  removePoint: () => {},
  resetSelectedPoints: () => {},
  togglePointAddOrRemove: () => {},
});

const INITIAL_ROUTE_STATE = {
  id: '',
  mode: 'DRIVING',
  origin: null,
  destination: null,
  points: [],
  details: {
    distance: null,
    duration: null,
  },
};

let DEFAULT_ORIGIN_OPTION: any = null;
let DEFAULT_DESTINATION_OPTION: any = null;

// @TODO: we'll remove it before the real production time
if (process.env.NODE_ENV === 'development') {
  DEFAULT_ORIGIN_OPTION = {
    rawData: {
      id: '3951d55a-db56-4806-9231-135855a20745',
      lng: '28.961',
      lat: '41.011',
      title: 'Istanbul University',
    },
    label: 'Istanbul University',
    value: '3951d55a-db56-4806-9231-135855a20745',
  };

  DEFAULT_DESTINATION_OPTION = {
    rawData: {
      id: '81b71f35-9d83-48e2-8229-7b8c7390a9ec',
      lng: '28.9850917',
      lat: '41.0370023',
      title: 'Taksim',
    },
    label: 'Taksim',
    value: '81b71f35-9d83-48e2-8229-7b8c7390a9ec',
  };
}

const INITIAL_NEARY_ROUTE_STATE = {
  points: [],
  steps: [],
  details: {
    distance: null,
    duration: null,
  },
};

const getFilteredPoints = (pointsData: PoiProps[]) => {
  return uniqBy(
    reject(
      pointsData,
      (point: PoiProps) =>
        isNil(point.id) || isNil(point.lng) || isNil(point.lat)
    ),
    'id'
  );
};

const getFilteredSteps = (stepsData: any): StepProps[] => {
  const formattedSteps = map(
    stepsData,
    ({ start_location: coordinates }: { start_location: StepProps }) => {
      if (!coordinates) {
        return null;
      }

      return {
        lat: Number(coordinates.lat),
        lng: Number(coordinates.lng),
      };
    }
  );

  return compact(formattedSteps);
};

const getNewRouteData = (): RouteProps => ({
  ...INITIAL_ROUTE_STATE,
  id: uid(),
  mode: 'DRIVING',
});

const orderPointsBySelectedIds = (
  points: PoiProps[],
  selectedPointIds: PoiProps['id'][]
) => {
  return points.sort((x) => (selectedPointIds.includes(x.id) ? -1 : 1));
};

const PlanYourTripsContextProvider: React.FC<{ children: ReactElement }> = ({
  children,
}) => {
  const app = useApp();
  const router = useRouter();
  const { travelMode, setTravelMode } = useContext(GoogleMapContext);

  // refs
  const nearyRouteControllerRef = useRef<AbortController | null>(null);

  // states
  const [selectedRoutePoints, setSelectedRoutePoints] = useState<PointProps[]>(
    []
  );
  const [routes, setRoutes] = useState<RouteProps[]>([]);
  const [currentRoute, setCurrentRoute] = useState<RouteProps>(
    getNewRouteData()
  );
  const [nearyRoute, setNearyRoute] = useState<NearyRouteProps>(
    INITIAL_NEARY_ROUTE_STATE
  );
  const [isEditing, setEditing] = useState<boolean>(false);
  const [editingUserRouteKey, setEditingUserRouteKey] = useState<string | null>(
    null
  );
  const [showCurrentRouteSummaryCard, setShowCurrentRouteSummaryCard] =
    useState<boolean>(false);
  const [showMyRoutes, setShowMyRoutes] = useState<boolean>(false);
  const [isRouteLoading, setRouteLoading] = useState<boolean>(false);
  const [selectedOriginOption, setSelectedOriginOption] = useState<
    SingleValue<OptionType>
  >(DEFAULT_ORIGIN_OPTION);
  const [selectedDestinationOption, setSelectedDestinationOption] = useState<
    SingleValue<OptionType>
  >(DEFAULT_DESTINATION_OPTION);
  const [showAdvancedFilters, setShowAdvancedFilters] =
    useState<boolean>(false);

  // data
  const queryParamsKey = router.query?.key;

  // actions
  // actions: route
  const saveCurrentRoute = (name: string): RouteProps => {
    const newRoute = {
      id: currentRoute.id, //  || uid()
      origin: selectedOriginOption?.rawData,
      destination: selectedDestinationOption?.rawData,
      details: nearyRoute.details,
      points: selectedRoutePoints,
      mode: travelMode,
      name,
    };

    // edit
    if (isEditing) {
      const requestBody = {
        parameters: {
          id: newRoute.id,
        },
        columns: [
          {
            name: 'duration',
            data: {
              refs: [],
              value: newRoute.details?.duration,
            },
          },
          {
            name: 'travelType',
            data: {
              refs: [],
              value: newRoute.mode,
            },
          },
          {
            name: 'origin',
            data: {
              refs: [newRoute.origin?.id],
              value: newRoute.origin?.id,
            },
          },
          {
            name: 'tourismAssets',
            data: {
              refs: map(newRoute.points, 'id'),
              value: map(newRoute.points, 'id'),
            },
          },
          {
            name: 'title',
            data: {
              refs: [],
              value: newRoute.name,
            },
          },
          {
            name: 'destination',
            data: {
              refs: [newRoute.destination?.id],
              value: newRoute.destination?.id,
            },
          },
          {
            name: 'distance',
            data: {
              refs: [],
              value: newRoute.details?.distance,
            },
          },
        ],
      };

      const request = queryService.update<any>(
        app.environment,
        {
          name: 'update-app-kayitli-rotalar',
          language: app.language,
        },
        requestBody
      );

      request.then((response) => {
        console.log('PYT:EDIT:response', response);
      });
    }
    // create
    else {
      const requestBody = {
        parameters: null,
        columns: [
          {
            name: 'duration',
            data: {
              refs: [],
              value: newRoute.details?.duration,
            },
          },
          {
            name: 'travelType',
            data: {
              refs: [],
              value: newRoute.mode,
            },
          },
          {
            name: 'origin',
            data: {
              refs: [newRoute.origin?.id],
              value: newRoute.origin?.id,
            },
          },
          {
            name: 'tourismAssets',
            data: {
              refs: map(newRoute.points, 'id'),
              value: map(newRoute.points, 'id'),
            },
          },
          {
            name: 'title',
            data: {
              refs: [],
              value: newRoute.name,
            },
          },
          {
            name: 'destination',
            data: {
              refs: [newRoute.destination?.id],
              value: newRoute.destination?.id,
            },
          },
          {
            name: 'distance',
            data: {
              refs: [],
              value: newRoute.details?.distance,
            },
          },
        ],
      };

      const request = queryService.create<any>(
        app.environment,
        {
          name: 'create-app-kayitli-rotalar',
          language: app.language,
        },
        requestBody
      );

      request.then((response) => {
        console.log('PYT:CREATE:response', response);
      });
    }

    setRoutes((prevRoutes) => uniqBy([newRoute, ...prevRoutes], 'id'));

    return newRoute;
  };

  const changeCurrentRoute = (id: PoiProps['id']) => {
    const findedRoute = find(routes, { id });
    if (findedRoute) {
      setCurrentRoute(findedRoute);
      setSelectedRoutePoints(findedRoute.points);
      setTravelMode(findedRoute.mode);

      if (!isNil(findedRoute.origin)) {
        setSelectedOriginOption(dataToSelectOption(findedRoute.origin));
      } else {
        setSelectedOriginOption(null);
      }

      if (!isNil(findedRoute.destination)) {
        setSelectedDestinationOption(
          dataToSelectOption(findedRoute.destination)
        );
      } else {
        setSelectedDestinationOption(null);
      }
    }
  };

  const resetCurrentRoute = () => {
    setCurrentRoute(getNewRouteData());
  };

  const deleteRouteById = async (id: PoiProps['id']) => {
    const requestBody = {
      parameters: {
        id: id,
      },
      columns: [],
    };

    const response = await queryService.remove<any>(
      app.environment,
      {
        name: 'delete-app-kayitli-rotalar',
        language: app.language,
      },
      requestBody
    );

    if (response) {
      console.log('PYT:DELETE:response', response);
    }

    setRoutes((prevRoutes) => reject(prevRoutes, { id }));

    // if current route is editing then remove it
    if (id === currentRoute.id) {
      setEditing(false);
      resetSelectedPoints();
      resetCurrentRoute();
    }
  };

  const resetNearyRoute = () => {
    setNearyRoute(INITIAL_NEARY_ROUTE_STATE);
  };

  const fetchPYTAssetDetails = async (points: PoiProps[]) => {
    const pointIds = map(points, 'id') || [];
    try {
      const searchParams = new URLSearchParams();
      searchParams.append('assetIds', pointIds.join('|'));
      searchParams.append('pageSize', '999');

      const response = await queryService.run<any>(
        app.environment,
        {
          name: 'plan-your-trip-assets-get-all',
          language: app.language,
          query: searchParams.toString(),
        },
        POINT_MAPPING
      );

      if (response && !isEmpty(response.data)) {
        const assets = compact(response.data) as PointProps[];
        return map(points, (point: PoiProps) => {
          const asset = find(assets, { id: point.id }) as PointProps;
          if (!asset) {
            return point;
          }

          // add extra subtitle param (district, city)
          const { city, district } = asset;
          const subtitle = !isEmpty(city)
            ? `${!isEmpty(district) ? `${district.title}/` : ''}${city.title}`
            : '';

          return {
            ...point,
            ...asset,
            subtitle,
          };
        });
      }
    } catch (err) {
      console.error('Error:', err);
    }

    return [];
  };

  const fetchPYTNearyRoute = async () => {
    // if the origin and the destination options are not selected
    if (isEmpty(selectedOriginOption) && isEmpty(selectedDestinationOption)) {
      resetNearyRoute();
      resetCurrentRoute();
      resetSelectedPoints();
      return;
    }

    // if selected only one option
    // if (isEmpty(selectedOriginOption) || isEmpty(selectedDestinationOption)) {
    //   const selectedOption = selectedOriginOption || selectedDestinationOption;
    //   const assets = await fetchPYTAssetsByDistrictOrCity({
    //     districtId: selectedOption?.rawData?.dirstrict?.id,
    //     cityId: selectedOption?.rawData?.city?.id,
    //   });
    //   setRoute({
    //     points: assets,
    //     steps: [],
    //   });
    //   return;
    // }

    try {
      const addedFormattedSelectedPois = selectedRoutePoints.map(
        ({ id, lat, lng }) => ({ id, lat, lng })
      );

      const payload: any = {
        mode: toLower(travelMode),
        pois: addedFormattedSelectedPois,
      };

      // append origin coordinates
      if (!isEmpty(selectedOriginOption)) {
        const { id, lat, lng } = toPlainObject(selectedOriginOption.rawData);
        payload.startId = id;
        payload.startLat = lat;
        payload.startLng = lng;

        if (isEmpty(selectedDestinationOption)) {
          payload.endId = id;
          payload.endLat = lat;
          payload.endLng = lng;
        }
      }

      // append destination coordinates
      if (!isEmpty(selectedDestinationOption)) {
        const { id, lat, lng } = toPlainObject(
          selectedDestinationOption.rawData
        );
        payload.endId = id;
        payload.endLat = lat;
        payload.endLng = lng;

        // if the origin option is empty then append to start params
        if (isEmpty(selectedOriginOption)) {
          payload.startId = id;
          payload.startLat = lat;
          payload.startLng = lng;
        }
      }

      // start loading
      setRouteLoading(true);

      // if there is an already request then cancel it
      if (!isNull(nearyRouteControllerRef.current)) {
        nearyRouteControllerRef.current.abort();
        nearyRouteControllerRef.current = null;
      }

      nearyRouteControllerRef.current = new AbortController();
      const signal = nearyRouteControllerRef.current.signal;

      const request = await fetch(
        `https://cms-ui-api.goturkiyetest.com.tr/goturkiye/triproute/en-us/neary-route`,
        {
          signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json', // JSON gönderileceğini belirt
          },
          body: JSON.stringify(payload),
        }
      );
      const response = await request.json();
      if (response && response.data) {
        const { pois, steps } = response.data || {};
        const points = compact(pois) as PoiProps[];
        const selectedRoutePointIds = map(selectedRoutePoints, 'id');

        // set to neary route
        const filteredPoints = getFilteredPoints(points);
        setNearyRoute((prevRoute) => ({
          ...prevRoute,
          points: unionBy(
            prevRoute.points,
            orderPointsBySelectedIds(filteredPoints, selectedRoutePointIds),
            'id'
          ),
          steps: getFilteredSteps(steps),
        }));

        // fetch poi (asset) details by poi.id
        const pointsWithDetails = await fetchPYTAssetDetails(filteredPoints);
        setNearyRoute((prevRoute) => ({
          ...prevRoute,
          points: orderPointsBySelectedIds(
            pointsWithDetails,
            selectedRoutePointIds
          ),
        }));
      } else {
        // if the route connot be found, then revert back to the previous travel mode
        // if (['TRANSIT', 'BICYCLING', 'WALKING'].includes(travelMode)) {
        //   setTravelMode('DRIVING');
        // } else if (['DRIVING'].includes(travelMode)) {
        //   setTravelMode('WALKING');
        // }

        // show response error mesage
        console.log('ERROR: (neary-route)', response.message);
        alert(response.message);
      }

      // close loading
      setRouteLoading(false);
    } catch (err) {
      if (err instanceof DOMException && err.name !== 'AbortError') {
        setRouteLoading(false);
        return;
      }
      console.error('Error:', err);
    }
  };

  const fetchMyRouteFromQueryByKey = async (key: string) => {
    try {
      const searchParams = new URLSearchParams();
      searchParams.append('key', key);

      const response = await queryService.run<any>(
        app.environment,
        {
          name: 'get-app-kayitli-rotalar',
          language: app.language,
          query: searchParams.toString(),
        }
        // MY_ROUTE
      );

      if (response) {
        const routeRawData = first(response.data) as any;
        if (routeRawData) {
          // set origin
          const originSelectOption = dataToSelectOption(routeRawData.origin, {
            labelProp: 'title',
            valueProp: 'id',
          });
          setSelectedOriginOption(originSelectOption);

          // set destination
          const destinationSelectOption = dataToSelectOption(
            routeRawData.destination,
            {
              labelProp: 'title',
              valueProp: 'id',
            }
          );
          setSelectedDestinationOption(destinationSelectOption);

          // set travel type
          setTravelMode(routeRawData.travelType);

          // set route
          const routeSelectedPoints = map(
            (routeRawData.tourismAssets || []) as PointProps[],
            toCoordinatesNumber
          );

          const route = {
            id: routeRawData.id,
            name: routeRawData.title,
            origin: toCoordinatesNumber(routeRawData.origin),
            destination: toCoordinatesNumber(routeRawData.destination),
            details: nearyRoute.details,
            points: routeSelectedPoints,
            mode: routeRawData.travelType,
          };

          setEditingUserRouteKey(key);
          setEditing(true);
          setCurrentRoute(route);
          setRoutes([route]);

          // set selected points
          setSelectedRoutePoints(routeSelectedPoints);
        }
      }
    } catch (err) {
      console.log('ERROR:', err);
    }
  };

  // actions: point
  const getSelectedPointOrder = (point: PointProps) => {
    const index = findIndex(selectedRoutePoints, { id: point.id });
    return index !== -1 ? index + 1 : null;
  };

  const isPointSelected = (point: PointProps) => {
    return !!find(selectedRoutePoints, { id: point.id });
  };

  const addPoint = (point: PointProps) => {
    setSelectedRoutePoints((prevPoints) =>
      uniqBy([...prevPoints, point], 'id')
    );
  };

  const removePoint = (point: PointProps) => {
    setSelectedRoutePoints((prevPoints) =>
      reject(prevPoints, { id: point.id })
    );
  };

  const resetSelectedPoints = () => {
    setSelectedRoutePoints((prevSelectedRoutePoints) =>
      !isEmpty(prevSelectedRoutePoints) ? [] : prevSelectedRoutePoints
    );
  };

  const togglePointAddOrRemove = (point: PointProps) => {
    const isSelectedPoint = isPointSelected(point);

    // remove from route
    if (isSelectedPoint) {
      removePoint(point);
    }
    // add to route
    else {
      addPoint(point);
    }
  };

  // effects
  useDebouncedEffect(
    () => {
      fetchPYTNearyRoute();
    },
    [
      selectedOriginOption,
      selectedDestinationOption,
      travelMode,
      selectedRoutePoints,
    ],
    300
  );

  useEffect(() => {
    if (!isNil(queryParamsKey)) {
      fetchMyRouteFromQueryByKey(queryParamsKey as string);
    }
  }, [queryParamsKey]);

  const value = {
    // states
    routes,
    setRoutes,
    currentRoute,
    setCurrentRoute,
    nearyRoute,
    setNearyRoute,
    isEditing,
    setEditing,
    editingUserRouteKey,
    isRouteLoading,
    selectedOriginOption,
    setSelectedOriginOption,
    setSelectedDestinationOption,
    selectedDestinationOption,
    showAdvancedFilters,
    setShowAdvancedFilters,
    showCurrentRouteSummaryCard,
    setShowCurrentRouteSummaryCard,
    showMyRoutes,
    setShowMyRoutes,
    selectedRoutePoints,

    // actions
    fetchPYTNearyRoute,
    saveCurrentRoute,
    changeCurrentRoute,
    deleteRouteById,
    resetCurrentRoute,
    isPointSelected,
    getSelectedPointOrder,
    addPoint,
    removePoint,
    resetSelectedPoints,
    togglePointAddOrRemove,
  };

  return (
    <PlanYourTripsContext.Provider value={value}>
      {children}
    </PlanYourTripsContext.Provider>
  );
};

export default PlanYourTripsContextProvider;
