import Icon from '@/components/base/icon';
import { IPlanYourTripRouteSummaryCardProps } from '@/components/widgets/plan-your-trip/components/layout/route-summary-card/types';
import { useContext, useEffect, useState } from 'react';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import {
  getShortDistance,
  getShortDuration,
} from '@/components/base/google-map/utils';
import { clsx } from '@/utils';
import { RouteProps } from '@/components/widgets/plan-your-trip/types';
import { selectOptionToData } from '@/utils/base/selectoptions';
import { isEmpty } from 'lodash';
import { GoogleMapContext } from '@/components/base/google-map/Context';

const PlanYourTripRouteSummaryCard: React.FC<
  IPlanYourTripRouteSummaryCardProps
> = () => {
  const { travelMode } = useContext(GoogleMapContext);
  const {
    nearyRoute,
    selectedRoutePoints,
    showCurrentRouteSummaryCard,
    setShowCurrentRouteSummaryCard,
    currentRoute,
    saveCurrentRoute,
    setShowMyRoutes,
    resetSelectedPoints,
    setEditing,
    changeCurrentRoute,
    selectedOriginOption,
    selectedDestinationOption,
    resetCurrentRoute,
  } = useContext(PlanYourTripsContext);

  // states
  const [savedRoute, setSavedRoute] = useState<RouteProps | null>(null);

  // data
  const distance = savedRoute
    ? savedRoute.details?.distance
    : nearyRoute.details?.distance;
  const duration = savedRoute
    ? savedRoute.details?.duration
    : nearyRoute.details?.duration;

  // actions
  const onClose = () => {
    setShowCurrentRouteSummaryCard(false);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const defaultName = (() => {
      let text = '';

      const origin = selectOptionToData(selectedOriginOption);
      const destination = selectOptionToData(selectedDestinationOption);

      // if selected origin option
      if (origin) {
        text = origin?.title;
      }

      // if selected origin option
      if (destination) {
        if (!isEmpty(text)) {
          text = `${text} to ${destination?.title}`;
        } else {
          text = destination?.title;
        }
      }

      return text;
    })();

    const formData = new FormData(e.currentTarget);
    const name = String(formData.get('name'));

    const route = saveCurrentRoute(!isEmpty(name) ? name : defaultName);

    setSavedRoute(route);
    resetSelectedPoints();
    resetCurrentRoute();
    setEditing(false);
  };

  const handleClickEditRoute = () => {
    if (savedRoute) {
      changeCurrentRoute(savedRoute.id);
      setEditing(true);
    }
    onClose();
  };

  const handleClickOpenMyRoutes = () => {
    onClose();
    setShowMyRoutes(true);
  };

  // effects
  useEffect(() => {
    if (!showCurrentRouteSummaryCard) {
      setSavedRoute(null);
    }
  }, [showCurrentRouteSummaryCard]);

  // renders
  if (!showCurrentRouteSummaryCard) {
    return null;
  }

  return (
    <div
      className={clsx('plan-your-trip-route-summary-card', {
        'plan-your-trip-route-summary-card--saved': savedRoute,
      })}
    >
      {/*begin::Close Button*/}
      <button
        className="plan-your-trip-route-summary-card-close-btn"
        onClick={onClose}
      >
        <Icon name="icon-close-2" />
      </button>
      {/*begin::Close Button*/}

      {/*begin::Title*/}
      {savedRoute ? (
        <div className="plan-your-trip-route-summary-card-title">
          <Icon name="icon-check" />
          <span>Route Saved Successfully!</span>
        </div>
      ) : (
        <div className="plan-your-trip-route-summary-card-title">
          <Icon name="icon-pin-save" />
          <span>Route Saved!</span>
        </div>
      )}

      {/*begin::Title*/}

      {/*begin::Description*/}
      {!savedRoute && (
        <div className="plan-your-trip-route-summary-card-description">
          Name your route to easily access it later and share with others.
        </div>
      )}
      {/*begin::Description*/}

      {/*begin::Details*/}
      <div className="plan-your-trip-route-summary-card-details">
        {savedRoute ? <p>{savedRoute.name}</p> : <p>Route Summary</p>}
        <div className="plan-your-trip-route-summary-card-details-item">
          <Icon name="icon-pin" />
          <span>
            {savedRoute ? savedRoute.points.length : selectedRoutePoints.length}{' '}
            Point
          </span>
        </div>
        {!isEmpty(distance) && (
          <div className="plan-your-trip-route-summary-card-details-item">
            <Icon name="icon-distance" />
            <span>{getShortDistance(distance)}</span>
          </div>
        )}
        {!isEmpty(duration) && (
          <div className="plan-your-trip-route-summary-card-details-item">
            <Icon name="icon-clock-2" />
            <span>{getShortDuration(duration)}</span>
          </div>
        )}
        <div className="plan-your-trip-route-summary-card-details-item active">
          {(() => {
            switch (travelMode) {
              case 'DRIVING':
              default:
                return <Icon name="icon-car" title="By Car" />;
              case 'TRANSIT':
                return <Icon name="icon-bus" title="On Foot" />;
              case 'WALKING':
                return <Icon name="icon-walk" title="Public Transport" />;
              // case 'BICYCLING':
              //   return <Icon name="icon-bicycle"  title=""/>;
            }
          })()}
        </div>
      </div>
      {/*begin::Details*/}

      {/*begin::Description*/}
      {savedRoute && (
        <div className="plan-your-trip-route-summary-card-description">
          You can view or edit your saved route at any time from your profile.
          Ready to start exploring?
        </div>
      )}
      {/*begin::Description*/}

      {/*begin::Form*/}
      {savedRoute ? (
        <div className="plan-your-trip-route-summary-card-form">
          <button
            className="plan-your-trip-route-summary-card-form-button"
            onClick={handleClickEditRoute}
          >
            Edit route
          </button>
          <button
            className="plan-your-trip-route-summary-card-form-button"
            onClick={handleClickOpenMyRoutes}
          >
            View my routes
          </button>
        </div>
      ) : (
        <form
          className="plan-your-trip-route-summary-card-form"
          onSubmit={onSubmit}
        >
          <input
            type="text"
            name="name"
            autoFocus
            placeholder="Enter route name..."
            className="plan-your-trip-route-summary-card-form-input"
            defaultValue={currentRoute.name}
          />
          <button
            type="submit"
            className="plan-your-trip-route-summary-card-form-button"
          >
            Save
          </button>
        </form>
      )}

      {/*end::Form*/}
    </div>
  );
};

export default PlanYourTripRouteSummaryCard;
