import React, { useContext } from 'react';
import Image from '@/components/base/image';
import Icon from '@/components/base/icon';
import { isEmpty } from 'lodash';
import Anchor from '@/components/base/anchor';
import { clsx } from '@/utils';
import { GoogleMapContext } from '@/components/base/google-map/Context';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import { IPlanYourTripPointListItem } from '@/components/widgets/plan-your-trip/components/layout/point-list/item/types';

const PlanYourTripPointListItem: React.FC<IPlanYourTripPointListItem> = ({
  point,
  onToggleAddRoute,
}) => {
  const { listViewMode } = useContext(GoogleMapContext);
  const { isPointSelected } = useContext(PlanYourTripsContext);

  // data
  const isSelected = isPointSelected(point);

  // actions
  const onClickToggleAddRoute = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onToggleAddRoute && onToggleAddRoute(point);
  };

  return (
    <Anchor
      className={clsx('google-map-point-list-items-item', {
        active: isSelected,
      })}
      motion={{
        initial: 'hidden',
        whileInView: 'show',
        viewport: { once: true },
      }}
      href={point.anchor?.path}
      {...point.anchor}
    >
      {listViewMode === 'detailed' && (
        <div className="google-map-point-list-items-item-image">
          <Image {...point.thumbnail} alt="point thumbnail" />
        </div>
      )}
      <div className="google-map-point-list-items-item-content">
        <div className="google-map-point-list-items-item-content-body">
          {!isEmpty(point.subtitle) && (
            <div className="google-map-point-list-items-item-content-body-subtitle">
              {point.subtitle}
            </div>
          )}
          {!isEmpty(point.title) && (
            <div className="google-map-point-list-items-item-content-body-title">
              {point.title}
            </div>
          )}
        </div>
        <button
          className={clsx('google-map-point-list-items-item-button', {
            'google-map-point-list-items-item-button--danger': isSelected,
          })}
          onClick={onClickToggleAddRoute}
        >
          <Icon name={isSelected ? 'icon-close-2' : 'icon-add-2'} />
          {isSelected ? (
            listViewMode === 'detailed' && <span>Remove</span>
          ) : listViewMode === 'detailed' ? (
            <span>Add Your Route</span>
          ) : (
            <span>Add</span>
          )}
        </button>
      </div>
    </Anchor>
  );
};

export default PlanYourTripPointListItem;
