import { isNil } from 'lodash';

export const getShortDistance = (text: string | null | undefined) => {
  if (isNil(text)) {
    return text;
  }

  return text.replace(' ', '');
};

export const getShortDuration = (text: string | null | undefined) => {
  if (isNil(text)) {
    return text;
  }

  return text
    .replace(' seconds', 's')
    .replace(' second', 's')
    .replace(' mins', 'm')
    .replace(' min', 'm')
    .replace(' hours', 'h')
    .replace(' hour', 'h')
    .replace(' days', 'd')
    .replace(' day', 'd')
    .replace(' weeks', 'w')
    .replace(' week', 'w');
};
