import Spinner from '@/components/base/spinner';
import { PlanYourTripsContext } from '@/components/widgets/plan-your-trip/contexts/PYTContext';
import { clsx } from '@/utils';
import { isEmpty } from 'lodash';
import { useContext } from 'react';

const PlanYourTripMapLoader: React.FC = () => {
  const { nearyRoute, isRouteLoading } = useContext(PlanYourTripsContext);

  // renders
  if (!isRouteLoading) {
    return null;
  }

  return (
    <Spinner
      size="small"
      className={clsx({
        'spinner-center': isEmpty(nearyRoute.points),
      })}
    />
  );
};

export default PlanYourTripMapLoader;
