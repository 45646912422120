import Icon from '@/components/base/icon';
import React, { useRef, useState } from 'react';
import { IPlanYourTripFiltersAdvancedFiltersOverlayProps } from '@/components/widgets/plan-your-trip/components/layout/filters/advanced-filters-overlay/types';
import { motion } from 'framer-motion';
import useElOutsideActionAlerter from '@/hooks/useElOutsideActionAlerter';

const animations = {
  overlay: {
    hidden: {
      opacity: 0,
      y: -50,
    },
    show: {
      opacity: 1,
      y: 0,
    },
  },
};

const PlanYourTripFiltersAdvancedFiltersOverlay: React.FC<
  IPlanYourTripFiltersAdvancedFiltersOverlayProps
> = ({ onClose }) => {
  const overlayRef = useRef(null);
  const [isClosing, setClosing] = useState<boolean>(false);
  // const { travelMode, setTravelMode } = useContext(GoogleMapContext);

  // actions
  // const handleChangeTravelModeBind = (mode: ITravelMode) => () => {
  //   setTravelMode(mode);
  // };

  // effects
  useElOutsideActionAlerter(overlayRef, () => {
    setClosing(true);
    setTimeout(() => {
      onClose && onClose();
    }, 500);
  });

  return (
    <motion.div
      ref={overlayRef}
      className="plan-your-trip-filters-advanced-overlay"
      variants={animations.overlay}
      initial="hidden"
      whileInView={isClosing ? 'hidden' : 'show'}
      viewport={{ once: true }}
    >
      {/*begin::Close Btn*/}
      <button
        className="plan-your-trip-filters-advanced-overlay-close-btn"
        onClick={onClose}
      >
        <Icon size="small" name="icon-close-2" />
      </button>
      {/*end::Close Btn*/}
      <div className="plan-your-trip-filters-advanced-overlay-container">
        {/*begin::Group*/}
        <div className="plan-your-trip-filters-advanced-overlay-group">
          {/*begin::Header*/}
          <div className="plan-your-trip-filters-advanced-overlay-group-header">
            <div className="plan-your-trip-filters-advanced-overlay-group-header-title">
              Advanced Filters
            </div>
            <div className="plan-your-trip-filters-advanced-overlay-group-header-description">
              Select the options for your route
            </div>
          </div>
          {/*end::Header*/}

          <div className="plan-your-trip-filters-advanced-overlay-group-main">
            {/*begin::Filters*/}
            <div className="plan-your-trip-filters-advanced-overlay-filters">
              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Cities, towns and villages</span>
              </label>
              {/*end::Filters Item*/}

              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Leisure parks</span>
              </label>
              {/*end::Filters Item*/}

              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Places of interest</span>
              </label>
              {/*end::Filters Item*/}

              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Tourist offices</span>
              </label>
              {/*end::Filters Item*/}

              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Natural sights</span>
              </label>
              {/*end::Filters Item*/}

              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Transport</span>
              </label>
              {/*end::Filters Item*/}

              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Beaches</span>
              </label>
              {/*end::Filters Item*/}

              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Spas</span>
              </label>
              {/*end::Filters Item*/}

              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Fiestas and events</span>
              </label>
              {/*end::Filters Item*/}

              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Paradors</span>
              </label>
              {/*end::Filters Item*/}

              {/*begin::Filters Seperator*/}
              <div className="plan-your-trip-filters-advanced-overlay-filters-seperator" />
              {/*end::Filters Seperator*/}

              {/*begin::Filters Item*/}
              <label className="plan-your-trip-filters-advanced-overlay-filters-item">
                <div className="plan-your-trip-filters-advanced-overlay-filters-item-checkbox">
                  <input type="checkbox" />
                  <i />
                </div>
                <span>Experiences to try on this route</span>
              </label>
              {/*end::Filters Item*/}
            </div>
            {/*end::Filters*/}

            {/*begin::Button*/}
            <button
              className="plan-your-trip-filters-advanced-overlay-group-button"
              onClick={onClose}
            >
              Apply
            </button>
            {/*end::Button*/}
          </div>
        </div>
        {/*end::Group*/}
      </div>
    </motion.div>
  );
};

export default PlanYourTripFiltersAdvancedFiltersOverlay;
